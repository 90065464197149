import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72')
];

export const server_loads = [0];

export const dictionary = {
		"/(main)": [11,[2]],
		"/auth/login": [~71],
		"/auth/setPassword": [72],
		"/(main)/dashboard": [12,[2]],
		"/(main)/dashboard/list": [14,[2]],
		"/(main)/dashboard/new": [15,[2]],
		"/(main)/dashboard/[dashboardId]/edit": [13,[2]],
		"/(main)/profile": [16,[2]],
		"/(main)/registries": [17,[2]],
		"/(main)/registries/customers": [18,[2]],
		"/(main)/registries/customers/create": [30,[2]],
		"/(main)/registries/customers/[customerId]": [19,[2,3]],
		"/(main)/registries/customers/[customerId]/general": [20,[2,3]],
		"/(main)/registries/customers/[customerId]/pricing": [21,[2,3]],
		"/(main)/registries/customers/[customerId]/pricing/create": [23,[2,3]],
		"/(main)/registries/customers/[customerId]/pricing/[pricingId]": [22,[2,3]],
		"/(main)/registries/customers/[customerId]/services": [24,[2,3]],
		"/(main)/registries/customers/[customerId]/users": [25,[2,3]],
		"/(main)/registries/customers/[customerId]/users/create": [29,[2,3]],
		"/(main)/registries/customers/[customerId]/users/[userId]": [26,[2,3,4]],
		"/(main)/registries/customers/[customerId]/users/[userId]/general": [27,[2,3,4]],
		"/(main)/registries/customers/[customerId]/users/[userId]/permissions": [28,[2,3,4]],
		"/(main)/registries/reportStatuses": [31,[2]],
		"/(main)/registries/users": [32,[2]],
		"/(main)/registries/users/create": [36,[2]],
		"/(main)/registries/users/[userId]": [33,[2,5]],
		"/(main)/registries/users/[userId]/general": [34,[2,5]],
		"/(main)/registries/users/[userId]/permissions": [35,[2,5]],
		"/(main)/reports": [37,[2,6]],
		"/(main)/reports/search": [39,[2,6]],
		"/(main)/reports/[reportId]": [38,[2,6]],
		"/(main)/requests": [40,[2]],
		"/(main)/requests/new": [45,[2]],
		"/(main)/requests/[requestId]": [41,[2,7]],
		"/(main)/requests/[requestId]/renew": [42,[2,7]],
		"/(main)/requests/[requestId]/report": [43,[2,7]],
		"/(main)/requests/[requestId]/request": [44,[2,7]],
		"/(main)/settings": [46,[2]],
		"/(main)/settings/colors": [47,[2]],
		"/(main)/settings/developers": [48,[2,8]],
		"/(main)/settings/developers/ateco": [49,[2,8]],
		"/(main)/settings/developers/currencies": [50,[2,8]],
		"/(main)/settings/developers/naics": [51,[2,8]],
		"/(main)/settings/developers/peoNumbers": [52,[2,8]],
		"/(main)/settings/developers/sic": [53,[2,8]],
		"/(main)/settings/industrySector": [54,[2]],
		"/(main)/settings/industrySector/create": [56,[2]],
		"/(main)/settings/industrySector/[industrySectorId]": [55,[2]],
		"/(main)/settings/monitoring": [57,[2]],
		"/(main)/settings/registry": [58,[2,9]],
		"/(main)/settings/registry/general": [59,[2,9]],
		"/(main)/settings/registry/users": [60,[2,9]],
		"/(main)/settings/registry/users/create": [64,[2,9]],
		"/(main)/settings/registry/users/[userId]": [61,[2,9,10]],
		"/(main)/settings/registry/users/[userId]/general": [62,[2,9,10]],
		"/(main)/settings/registry/users/[userId]/permissions": [63,[2,9,10]],
		"/(main)/settings/roles": [65,[2]],
		"/(main)/settings/roles/new": [67,[2]],
		"/(main)/settings/roles/[roleId]": [66,[2]],
		"/(main)/settings/socialSubjects": [68,[2]],
		"/(main)/settings/socialSubjects/create": [70,[2]],
		"/(main)/settings/socialSubjects/[socialSubjectsId]": [69,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';