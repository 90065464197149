import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';
import { dev } from '$app/environment';
import { env } from '$env/dynamic/public';

if(!dev) {
  Sentry.init({
    dsn: 'https://2dc0ac762fead6be367ed0c4cef8bd88@o4507225626640384.ingest.de.sentry.io/4507225629917264',
    tracesSampleRate: 1.0,
  
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
  
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    
    // If you don't want to use Session Replay, just remove the line below:
    integrations: [replayIntegration()],
    environment: env.PUBLIC_SENTRY_ENVIRONMENT || 'production'
  });
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = dev ? () => {} : handleErrorWithSentry();
